import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import Styles from './more-posts.module.scss';


export default ({ posts }) => {
    return (
        <div className="">
            <div className="row">
                {
                    posts.nodes.slice(0, 4).map((post, index) => (
                        <div key={index} className="col-12 col-md-6 mb-4">
                            <Link to={`/${post.uid}`} className="unstyled-link">
                                <BackgroundImage
                                    Tag="div"
                                    className={`${Styles.image} rounded-4px`}
                                    fluid={post.data.title_image.fluid}
                                >
                                </BackgroundImage>
                                <Link to={`/${post.uid}`} className="unstyled-link">
                                    <h3 className={`font-weight-bold text-pink mt-2 ${Styles.postHeading}`}>{post.data.title.text}</h3>
                                </Link>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
