import { Link } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { convertTagForUrl } from '../../../utils/text';
import Styles from './blog-header.module.scss';
import Img from 'gatsby-image';

const TagList = ({tags}) => (
    <ul className="list-unstyled">
        {tags.map((tag, index) => {
            return <li key={index} className={`${Styles.tag}`}>
                <Link className="unstyled-link" to={`/blog/${convertTagForUrl(tag)}`}>{tag.toLowerCase()}</Link>
            </li>
        })}
    </ul>
);

export default ({ title, image, firstPublicationDate, author, tags }) => {
    // Todo: Better handling of missing data
    if (!author) {
        return <React.Fragment></React.Fragment>;
    }
    
    author = author.document.data;
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h1 className={`mb-3 ${Styles.title}`}>{title}</h1>
                    <TagList tags={tags}></TagList>
                    <small className="mb-3">Veröffentlicht am {firstPublicationDate} von {author.first_name.text} {author.last_name.text}</small>
                    <small>Bei den mit Sternchen markierten Links handelt es sich um Affiliate Links.<br/>Erfahre mehr in der <Link to="/datenschutz" className="unstyled-link link-underline">Datenschutzerklärung</Link>.</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Img className="w-100 mt-4 rounded-4px" fluid={image.fluid} alt={title}/>
                </Col>
            </Row>
        </React.Fragment>
    );
};
