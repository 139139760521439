import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import MorePosts from '../../components/blog/more-posts/more-posts';
import { FacebookIcon2, LinkedInIcon, MailIcon, PinterestIcon, TwitterIcon } from '../../components/global/icons/icons';
import Layout from '../../components/global/layout/layout';
import MailchimpSignup from '../../components/global/mailchimp-signup/mailchimp-signup';
import Header from '../../components/post/blog-header/blog-header';
import Comments from '../../components/post/comments/comments';
import EmbedSlice from '../../components/post/embed-slice/embed-slice';
import H2Slice from '../../components/post/h2-slice/h2-slice';
import ImageSlice from '../../components/post/image-slice/image-slice';
import Pinterest from '../../components/post/pinterest/pinterest';
import QuoteSlice from '../../components/post/quote-slice/quote-slice';
import TextSlice from '../../components/post/text-slice/text-slice';
import { toKebabCase } from '../../utils/text';
import Styles from './post.module.scss';

const handleAnchorScroll = (event, targetID) => {
  event.preventDefault();
  const targetElement = document.getElementById(targetID);
  const domRect = targetElement.getBoundingClientRect();
  const currentYOffset = window.scrollY;
  const paddingTop = 85;
  const targetTop = currentYOffset + domRect.top - paddingTop;
  window.scrollTo(0, targetTop);
}

const Li = ({heading, id}) => (
  <li className={`unstyled-link ${Styles.postLink}`}><a onClick={e => handleAnchorScroll(e, id)} href={`#${id}`}>{heading}</a></li>
);

const PostList = ({posts}) => (
    <ul className={`list-unstyled sidenav-text mb-0`}>
        {posts.nodes.map((post, index) => {
            return <li key={index} className={`unstyled-link ${Styles.postLink}`}><Link to={`/${post.uid}`}>{post.data.title.text}</Link></li>
        })}
    </ul>
);

const Post = ({ data: { prismicBlogPost: post, allPrismicBlogPost: otherPosts } }) => {
  const headingMap = {};
  if (Array.isArray(post.data.body)) {
    post.data.body.forEach(slice => {
      if (slice.__typename === 'PrismicBlogPostBodyH2Heading') {
        const h2 = slice.primary.heading.text;
        const id = toKebabCase(h2);
        headingMap[id] = h2;
      }
    });
  } else {
    post.data.body = [];
  }

  return (
    <Layout footerPosts={otherPosts} title={post.data.title.text} description={post.data.snippet_text ? post.data.snippet_text.text : post.data.excerpt.text} image={post.data.title_image.url}>
      <Container className={`py-5 my-5 ${Styles.post}`}>
        <Row>
          <Col xs="12" lg="8">
            <Header firstPublicationDate={post.first_publication_date}
              title={post.data.title.text}
              author={post.data.author}
              tags={post.tags}
              image={post.data.title_image}>
            </Header>
          </Col>
          <Col xs="12" lg={{ span: 3, offset: 1}} className="d-none d-lg-block">
            {post.data.author && (
              <div className={`rounded-4px bg-beige mb-4 pb-2 ${Styles.shadow}`}>
                  <div className={``}>
                      <Img className={`w-100 rounded-4px no-border-bottom-radius`} fluid={post.data.author.document.data.image.fluid} alt={'Bild von ' + post.data.author.document.data.first_name.text}/>
                      <strong className={`bg-pink d-block mb-2 px-2 sidenav-heading text-center text-white`}>{post.data.author.document.data.first_name.text} {post.data.author.document.data.last_name.text}</strong>
                      <div className={`sidenav-text px-2`} dangerouslySetInnerHTML={{ __html: post.data.author.document.data.bio_text.html }}></div>
                  </div>
              </div>
            )}
            <div className={`rounded-4px bg-beige pb-2 ${Styles.shadow}`}>
                <strong className={`no-border-bottom-radius bg-pink d-block mb-2 px-2 rounded-4px sidenav-heading text-center text-white`}>Neueste Beiträge</strong>
                <div className="px-2">
                  <PostList posts={otherPosts}></PostList>
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="3" className="offset-lg-1 order-lg-2">
            <div className="sticky-top blog-sidenav-top">
              <div className={`pt-4`}>
                <div className={`bg-beige rounded-4px pb-2 ${Styles.shadow}`}>
                  <strong className="no-border-bottom-radius bg-pink d-block mb-2 px-2 rounded-4px sidenav-heading text-center text-white">Inhalt</strong>
                  <ol className="pl-4 pr-2 sidenav-text mb-0">
                      {Object.keys(headingMap).map(id => {
                          return <Li key={id} heading={headingMap[id]} id={id}></Li>;
                      })}
                  </ol>
                </div>
              </div>
              <div className={`pt-4 d-none d-lg-block`}>
                <div className={`bg-beige rounded-4px pb-2 ${Styles.shadow}`}>
                  <strong className="no-border-bottom-radius bg-pink d-block mb-2 px-2 rounded-4px sidenav-heading text-center text-white">Teilen</strong>
                  <div className="py-2 icon-container d-flex justify-content-around">
                    <FacebookIcon2 pathClass="fill-grey" linkClass="" size={24} url={`https://www.facebook.com/sharer/sharer.php?u=${'https://www.mindfoe.de/' + post.uid}`}/>
                    <TwitterIcon pathClass="fill-grey" linkClass="" size={24} url={`https://twitter.com/home?status=${'https://www.mindfoe.de/' + post.uid}`}/>
                    <PinterestIcon pathClass="fill-grey" linkClass="" size={24} url={`https://pinterest.com/pin/create/button/?url=${'https://www.mindfoe.de/' + post.uid}&media=&description=${post.data.title.text}`}/>
                    <MailIcon pathClass="fill-grey" linkClass="" size={24} url={`mailto:calvin@mindfoe.de?subject="${post.data.title.text}" auf mindfoe.de&body=${'https://www.mindfoe.de/' + post.uid}`}/>
                    <LinkedInIcon pathClass="fill-grey" linkClass="" size={24} url={`https://www.linkedin.com/shareArticle?mini=true&url=${'https://www.mindfoe.de/' + post.uid}&title=${post.data.title.text}`}/>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" lg="8" className="pt-4">
            {post.data.body.map(slice => {
              switch (slice.__typename) {
                case 'PrismicBlogPostBodyEmbed': return <EmbedSlice key={slice.id} slice={slice}></EmbedSlice>;
                case 'PrismicBlogPostBodyText': return <TextSlice key={slice.id} slice={slice}></TextSlice>;
                case 'PrismicBlogPostBodyQuote': return <QuoteSlice key={slice.id} slice={slice}></QuoteSlice>;
                case 'PrismicBlogPostBodyImage': return <ImageSlice key={slice.id} slice={slice}></ImageSlice>;
                case 'PrismicBlogPostBodyH2Heading': return <H2Slice key={slice.id} text={slice.primary.heading.text} id={toKebabCase(slice.primary.heading.text)}></H2Slice>;
                case 'PrismicBlogPostBodyMailSignup': return <MailchimpSignup key={slice.id} slice={slice} alwaysVertical={true} outerClass="py-1 my-5"></MailchimpSignup>;
                case 'PrismicBlogPostBodyPinterest': return <Pinterest key={slice.id} slice={slice}/>;
                case 'PrismicBlogPostBodyDivider': return <hr className="my-4 my-md-5"></hr>;
                default:
                  console.log(`could not render slice ${slice.__typename}`);
                  return '';
              }
            })}
            <hr className="my-5"></hr>
            <Comments uid={post.uid}/>
            <hr className="my-5"></hr>
            <h1 className="mb-4">Weitere Beiträge</h1>
            <MorePosts posts={otherPosts}/>
          </Col>
          <Col xs="12" className="d-lg-none pt-5">
            <hr></hr>
            <strong className="d-block my-2 text-center" style={{ fontSize: '1.4rem' }}>Teilen</strong>
            <div className="py-2 icon-container d-flex justify-content-around">
              <FacebookIcon2 pathClass="fill-grey" linkClass="" size={32} url={`https://www.facebook.com/sharer/sharer.php?u=${'https://www.mindfoe.de/' + post.uid}`}/>
              <TwitterIcon pathClass="fill-grey" linkClass="" size={32} url={`https://twitter.com/home?status=${'https://www.mindfoe.de/' + post.uid}`}/>
              <PinterestIcon pathClass="fill-grey" linkClass="" size={32} url={`https://pinterest.com/pin/create/button/?url=${'https://www.mindfoe.de/' + post.uid}&media=&description=${post.data.title.text}`}/>
              <MailIcon pathClass="fill-grey" linkClass="" size={32} url={`mailto:calvin@mindfoe.de?subject="${post.data.title.text}" auf mindfoe.de&body=${'https://www.mindfoe.de/' + post.uid}`}/>
              <LinkedInIcon pathClass="fill-grey" linkClass="" size={32} url={`https://www.linkedin.com/shareArticle?mini=true&url=${'https://www.mindfoe.de/' + post.uid}&title=${post.data.title.text}`}/>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Post;

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    allPrismicBlogPost(
      limit: 4,
      filter: {
        uid: {
          ne: $uid
        }
      },
      sort: {
        fields: first_publication_date,
        order: DESC
      }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          snippet_text {
            text
          }
          title_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        first_publication_date(formatString: "DD.MM.YYYY")
        tags
      }
    }
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      data {
        body {
          __typename
          ... on PrismicBlogPostBodyMailSignup {
            id
            primary {
              title1 {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyH2Heading {
            id
            id
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            id
            primary {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogPostBodyEmbed {
            id
            primary {
              embed {
                html
                provider_name
              }
            }
          }
          ... on PrismicBlogPostBodyText {
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            id
            primary {
              quote {
                html
              }
              name_of_the_author {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyDivider {
            id
          }
        }
        title {
          text
        }
        title_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        excerpt {
          text
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                first_name {
                  text
                }
                last_name {
                  text
                }
                image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
                bio_text {
                  html
                }
              }
            }
          }
        }
      }
      first_publication_date(formatString: "DD.MM.YYYY")
      tags
    }
  }
`
