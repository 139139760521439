import * as moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import * as Styles from './comment.module.scss';

export default props => {
    return (
        <div className={Styles.comment}>
            <span>
                <strong>{props.comment.author}</strong>{' '}
                {
                    (props.comment.timestamp && typeof props.comment.timestamp.seconds === 'number') ? (
                        <React.Fragment>
                            {' '}
                            <span title={new Date(props.comment.timestamp.seconds * 1000).toLocaleString()}>
                                {moment(props.comment.timestamp.seconds * 1000).fromNow()}
                            </span>
                        </React.Fragment>
                    ) : ''
                }
            </span>
            <p dangerouslySetInnerHTML={{ __html: props.comment.text }}></p>
        </div>
    )
}
