import React from 'react';
import * as Styles from './quote-slice.module.scss';

export default ({slice}) => {
    if (!(slice?.primary?.quote?.html)) {
        return '';
    }
    
    return (
        <blockquote class={`${Styles.blockquote}`}>
            <div dangerouslySetInnerHTML={{ __html: slice.primary.quote.html }}></div>
            {
                slice?.primary?.name_of_the_author?.text ? (
                    <cite>{slice.primary.name_of_the_author.text}</cite>
                ) : ''
            }
        </blockquote>
    );
}
