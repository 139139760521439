import React from 'react';
import Img from 'gatsby-image';

export default ({ slice }) => (
    <div className="row">
        <div className="col-12 col-md-6 col-lg-7 mb-4 mb-md-0">
            <Img className="w-100" fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
        </div>
        <div className="col-12 col-md-6 col-lg-5">
            <div dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}></div>
        </div>
    </div>
);
