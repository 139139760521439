import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import * as Styles from './add-comment.module.scss';

export default props => {
    const { register, handleSubmit } = useForm();

    const [authorInputUsed, setAuthorInputUsed] = useState(false);
    const [textInputUsed, setTextInputUsed] = useState(false);

    const getSubmitButton = () => document.getElementById('js-add-comment-button');

    const recaptchaExpire = () => {
        getSubmitButton().setAttribute('disabled', true);
    };
    const recaptchaVerify = (response) => {
        getSubmitButton().removeAttribute('disabled');
    };

    return (
        <React.Fragment>
            <strong>Kommentar verfassen</strong>
            <form onSubmit={handleSubmit(props.onSubmit)} className="d-flex flex-column mt-2">
                <input onInput={() => setAuthorInputUsed(true)} className={`${Styles.input} mb-2`} name="author" placeholder="Name" ref={register({ required: true })} />
                <textarea onInput={() => setTextInputUsed(true)} className={`${Styles.input} mb-3`} name="text" placeholder="Gib einen Kommentar ein..." rows="2" ref={register({ required: true })}></textarea>

                {
                    authorInputUsed && textInputUsed ? (
                        <div>
                            <ReCAPTCHA
                                sitekey="6LcPz9AUAAAAAIxBJz31h7OKX6GIj0d_eZBTwPrp"
                                hl="de"
                                onChange={recaptchaVerify}
                                onExpired={recaptchaExpire}
                            />
                            <button id="js-add-comment-button" disabled className={`${Styles.button} mt-2`} type="submit">Kommentieren</button>
                        </div>
                    ) : ''
                }
            </form>
        </React.Fragment>
    )
}
