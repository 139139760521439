import { useFirebase } from 'gatsby-plugin-firebase';
import React, { useState } from 'react';
import AddComment from './components/add-comment';
import Comment from './components/comment';

const defaultDocument = {
    enable: true,
    comments: []
};

export default props => {
    const [firestoreData, setFirestoreData] = useState();
    const [hideAddComment, setHideAddComment] = useState(false);

    useFirebase(firebase => {
        firebase
            .firestore()
            .collection('comments')
            .doc(props.uid)
            .onSnapshot({
                next: snapshot => {
                    setFirestoreData(snapshot.data() || defaultDocument);

                    // Create the default document if no data exists yet
                    if (!snapshot.data() || !Array.isArray(snapshot.data().comments)) {
                        firebase
                            .firestore()
                            .collection('comments')
                            .doc(props.uid)
                            .set(defaultDocument);
                    }
                }
            });
        window.addEventListener('mindfoe-comment-added', event => {
            setHideAddComment(true);
            const payload = {
                ...event.detail.comment,
                timestamp: new Date()
            };
            firebase
                .firestore()
                .collection('comments')
                .doc(props.uid).update({
                    comments: [
                        ...event.detail.comments,
                        payload
                    ]
                });
        })
    }, []);

    const onSubmit = data => {
        window.dispatchEvent(new CustomEvent('mindfoe-comment-added', {
            detail: {
                comment: data,
                comments: firestoreData.comments || []
            }
        }));
    };

    if (!(firestoreData && Array.isArray(firestoreData.comments))) {
        return (
            <AddComment onSubmit={onSubmit} />
        );
    }

    return (
        <div>
            <h1>Kommentare</h1>
            {
                ((firestoreData.enable !== false) && !hideAddComment) ? (
                    <AddComment onSubmit={onSubmit} />
                ) : ''
            }
            {
                firestoreData.comments.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds).map((comment, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Comment comment={comment}/>
                            {
                                (index + 1) < firestoreData.comments.length ? (
                                    <hr></hr>
                                ) : ''
                            }
                        </React.Fragment>
                    );
                })
            }
        </div>
    );
}
