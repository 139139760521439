import React from 'react';

export default ({slice}) => {
    let className = 'mb-4';
    if (slice.primary.embed.provider_name !== 'Instagram') {
        className += ' embed-responsive embed-responsive-16by9';
        slice.primary.embed.html = slice.primary.embed.html.replace('<iframe', '<iframe class="embed-responsive-item"');
    }
    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: slice.primary.embed.html }}>
        </div>
    );
}
