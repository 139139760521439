import React from 'react';
import Img from 'gatsby-image';

export default ({slice}) => {
    if (!slice.primary.description) {
        slice.primary.description = {
            text: ''
        };
    }
    return (
        <React.Fragment>
            <Img className="w-100 mb-4" fluid={slice.primary.image.fluid} alt={slice.primary.description.text}/>
            <div dangerouslySetInnerHTML={{ __html: slice.primary.description.html }}></div>
        </React.Fragment>
    );
}
